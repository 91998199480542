import { useStores } from "@hooks/use-stores"
import { Redirect } from "@reach/router"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"

const MyAccount = ({ location }) => {
  const { hash } = location
  const [isClient, setIsClient] = useState(false)

  const { authSite } = useStores()

  useEffect(() => {
    setIsClient(true)
  }, [])

  const { user } = authSite

  const userType = _get(user, "type", false)

  if (!isClient) return null

  if (userType) {
    return <Redirect to="/conta-criador" noThrow />
  }

  return <Redirect to={`/conta-assinante${hash}`} noThrow />
}

export default MyAccount
